@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
/*===== VARIABLES CSS =====*/
:root{
  /*===== Cores =====*/
  --first-color: #1890ff;
  --first-color-dark:#0050b3;
  --first-color-font-lignt: #595959;
  --first-color-light: #A49EAC;
  --first-color-lighten: #f5f5f5;

  /*===== Fontes and Tipografia =====*/
  --body-font: 'Open Sans', sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
}

@media screen and (min-width: 768px){
  :root{
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
  }
}

/*===== BASE =====*/
*,::before,::after{
  box-sizing: border-box;
}

body{
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--body-font);
  font-size: .938rem;
  font-size: var(--normal-font-size);
  color: #0050b3;
  color: var(--first-color-dark);
}

h1{
  margin: 0;
}

img{
  max-width: 100%;
  height: auto;
  display: block;
}

button{
  border: none;
}

.login{
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.login__content{
  display: grid;
}

.login__img{
  justify-self: center;
}

.login__img img{
  width: 310px;
  margin-top: 1.5rem;
}

.login__forms{
  position: relative;
  height: 368px;
}

.login__registre{
  position: absolute;
  bottom: 1rem;
  width: 100%;
  background-color: #f5f5f5;
  background-color: var(--first-color-lighten);
  padding: 2rem 1rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 8px 20px rgba(35,0,77,.2);
  -webkit-animation-duration: .4s;
          animation-duration: .4s;
  -webkit-animation-name: animate-login;
          animation-name: animate-login;
}

@-webkit-keyframes animate-login{
  0%{
    transform: scale(1,1);
  }
  50%{
    transform: scale(1.1,1.1);
  }
  100%{
    transform: scale(1,1);
  }
}

@keyframes animate-login{
  0%{
    transform: scale(1,1);
  }
  50%{
    transform: scale(1.1,1.1);
  }
  100%{
    transform: scale(1,1);
  }
}

.login__title{
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
  margin-bottom: 2rem;
}

.login__box{
  display: grid;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
  grid-column-gap: .5rem;
  -webkit-column-gap: .5rem;
          column-gap: .5rem;
  padding: 1.125rem 1rem;
  background-color: #FFF;
  margin-top: 1rem;
  border-radius: .5rem;
}

.login__icon{
  font-size: 1.5rem;
  color: #1890ff;
  color: var(--first-color);
}

.login__input{
  border: none;
  outline: none;
  font-size: .938rem;
  font-size: var(--normal-font-size);
  font-weight: 700;
  color: #595959;
  color: var(--first-color-font-lignt);
}

.login__input::-webkit-input-placeholder{
  font-size: .938rem;
  font-size: var(--normal-font-size);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--body-font);
  color: #A49EAC;
  color: var(--first-color-light);
}

.login__input:-ms-input-placeholder{
  font-size: .938rem;
  font-size: var(--normal-font-size);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--body-font);
  color: #A49EAC;
  color: var(--first-color-light);
}

.login__input::-ms-input-placeholder{
  font-size: .938rem;
  font-size: var(--normal-font-size);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--body-font);
  color: #A49EAC;
  color: var(--first-color-light);
}

.login__input::placeholder{
  font-size: .938rem;
  font-size: var(--normal-font-size);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--body-font);
  color: #A49EAC;
  color: var(--first-color-light);
}

.login__button{
  display: block;
  padding: 1rem;
  margin: 2rem 0;
  background-color: #1890ff;
  background-color: var(--first-color);
  color: #FFF;
  font-weight: 600;
  text-align: center;
  border-radius: .5rem;
  transition: .3s;
  cursor: pointer;
  width: 100%;
}

.login__error{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: .813rem;
  font-size: var(--small-font-size);
  border: 1px solid #ffccc7;
  padding: 8px;
  background-color: #fff2f0;
  margin-top: 1rem;
  border-radius: .2rem;

}

.login__list-error{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 100%;

}

.login__list-error span{
  color: #595959;
  color: var(--first-color-font-lignt);
  text-align: left;
  font-size: 14px;
  line-height: normal;
}

.login__image-erro{
  display: flex;
  align-items: center;
  justify-items: center;
  width: 30px;

}

.login__image-erro img{
  width: 20px;
  height: 20px;
}

@-webkit-keyframes animate-button{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.01);
  }
  100%{
    transform: scale(1);
  }
}

@keyframes animate-button{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.01);
  }
  100%{
    transform: scale(1);
  }
}

.login__button:focus, .login__button:active{
  outline: none;
}

.login__button:active{
  outline: none;
  -webkit-animation-duration: .4s;
          animation-duration: .4s;
  -webkit-animation-name: animate-button;
          animation-name: animate-button;
}

.login__button:hover{
  background-color: #0050b3;
  background-color: var(--first-color-dark);
}

@media screen and (min-width: 576px){
  .login__forms{
    width: 348px;
    justify-self: center;
  }
}

@media screen and (min-width: 1024px){
  .login{
    height: 100vh;
    overflow: hidden;
  }

  .login__content{
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    align-items: center;
    margin-left: 10rem;
  }

  .login__img{
    display: flex;
    width: 600px;
    height: 588px;
    background-color: #f5f5f5;
    background-color: var(--first-color-lighten);
    border-radius: 1rem;
    padding-left: 1rem;
  }

  .login__img img{
    width: 390px;
    margin-top: 0;
  }

  .login__registre{
    left: -11rem;
    bottom: -2rem
  }
}
body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home {
  position: relative;
  height: 100%; 
  width: 100%; 

}

.home::after {
  content: "";
  background-image: url(/static/media/undraw_dados.bfc773d4.svg);
  opacity: 0.5;
  background-repeat: no-repeat;  
  background-size:30%;
  background-position: bottom 10px right 10px; 
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}


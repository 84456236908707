.home {
  position: relative;
  height: 100%; 
  width: 100%; 

}

.home::after {
  content: "";
  background-image: url('../assets/undraw_dados.svg');
  opacity: 0.5;
  background-repeat: no-repeat;  
  background-size:30%;
  background-position: bottom 10px right 10px; 
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
